/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import {
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Text,
  theme,
} from "@nordcloud/gnui";
import { DateRange, getRangeLabel } from "~/components/Charts";
import {
  DateRangeSelector,
  EstatePeriod as Period,
} from "~/components/DateRangeSelector";
import { noop } from "~/tools";
import { usePeriodSelector } from "./usePeriodSelector";

type Props = {
  period: Period;
  onChange: (selectedPeriod: Period, selectedRange?: DateRange) => void;
  range?: DateRange;
  rangeStartDate?: string;
  rangeEndDate?: string;
  clearRange?: () => void;
};

const defaultSelectButtonWidth = "3rem";

export function PeriodSelector({
  period,
  onChange,
  range,
  rangeStartDate,
  rangeEndDate,
  clearRange = noop,
}: Props) {
  const { rangeLabel, setRangeLabel, setRange, onPeriodChange, handleClear } =
    usePeriodSelector({ onChange, clearRange });

  useEffect(() => {
    if (period === Period.RANGE || rangeStartDate) {
      setRangeLabel(getRangeLabel(range));
    }
  }, [period, range, onChange, rangeStartDate, rangeEndDate, setRangeLabel]);

  return (
    <FlexContainer
      data-testid="period-selector"
      css={{
        paddingBottom: theme.spacing.spacing01,
        marginRight: theme.spacing.spacing07,
      }}
    >
      <Text
        size="sm"
        mb="0"
        mr={theme.spacing.spacing04}
        weight="medium"
        color={theme.color.text.text02}
      >
        Period:
      </Text>
      <MultipleSelect size="small">
        <SelectButton
          name="previous-month"
          value="0"
          labelText="Previous Month"
          isActive={period === Period.PREVIOUS_MONTH}
          data-testid="prev-month"
          style={{
            minWidth: defaultSelectButtonWidth,
          }}
          onClick={() => onPeriodChange(Period.PREVIOUS_MONTH)}
        />
        <SelectButton
          name="current-month"
          value="1"
          labelText="Current Month"
          isActive={period === Period.CURRENT_MONTH}
          style={{ minWidth: defaultSelectButtonWidth }}
          data-testid="current-month"
          onClick={() => onPeriodChange(Period.CURRENT_MONTH)}
        />
        <DateRangeSelector
          showPreselects
          isActive={period === Period.RANGE}
          label={rangeLabel}
          period={period}
          range={range}
          data-testid="date-range"
          onApply={setRange}
          onClear={handleClear}
        />
      </MultipleSelect>
    </FlexContainer>
  );
}
